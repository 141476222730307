import React from 'react'

const like = () => {
  return (
    <div className='p-2 md:p-4'>
    <h2 className= "text-lg md:text-2xl font-bold text-red-600">
        Barang yang disukai
    </h2>

    <div className=''>
        {/*display cart items*/}
        <div className=''>
        {/*total cart item*/}
        </div>
    </div>
</div>
  )
}

export default like